<template>
    <b-modal id="modal-link" content-class="shadow">
        <h4 class="font-weight-bolder mx-auto text-center mt-2">
            <a class="text-primary" methods="_blank">{{ link }}</a>
        </h4>
        <p class="mb-2 mx-auto text-center">Communiquez le pour que les stagiaires s'inscrivent à la classe</p>
        <template #modal-footer="{ ok }">
            <b-button variant="primary" @click="copierElement()">Copier le lien</b-button>
            <b-button variant="secondary" @click="ok">Fermer</b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: 'ModalShowLink',
    props: {
        link: {
            type: String,
            required: true
        }
    },
    computed: {
        plateformeHref() {
            return `${process.env.VUE_APP_PLATEFORME_URL}`;
        },
        token() {
            return this.link.split('/').pop();
        }
    },
    methods: {
        copierElement() {
            var copyText = `${this.plateformeHref}/connexion/${this.token}`;

            navigator.clipboard.writeText(copyText);

            this.$bvToast.toast('Lien copié dans le presse-papier', {
                title: 'Succès',
                variant: 'success',
                solid: true
            });
        }
    }
    // Vos options et méthodes ici
};
</script>

<style scoped>
/* Vos styles ici */
</style>
