<template>
    <b-modal button-size="sm" id="modal-update-link" title="Générer un lien d'inscription">
        <template #modal-header>Modifier le lien</template>
        <template #default>
            <section>
                <b-form ref="form">
                    <b-overlay :show="loading" rounded="sm">
                        <!-- SELECT CLASS -->
                        <b-form-group v-if="!classeId" label="Classe *" label-for="select-class">
                            <b-form-select name="select-class" v-model="form.classes_customers_id" :options="classesOptions" />
                        </b-form-group>
                        <!-- SELECT CUSTOMER-->
                        <b-form-group label="Etablissement de rattachement des stagiaires" label-for="select-customer">
                            <b-form-select
                                name="select-customer"
                                v-model="customerSelected"
                                :options="customersOptions"
                                @change="checkCustomerSelected"
                            />
                        </b-form-group>
                        <b-form-group>
                            <b-form-checkbox disabled v-model="form.trainee_choice">
                                Laisser le stagiaire choisir son établissement de rattachement
                            </b-form-checkbox>
                        </b-form-group>
                        <!-- EXPIRY -->
                        <b-form-group label="Date d'expiration :" label-for="expiry-date">
                            <div class="d-flex">
                                <b-form-datepicker
                                    :min="new Date()"
                                    id="expiry-date"
                                    v-model="form.expiry"
                                    placeholder="Choisissez une date"
                                />
                                <b-button
                                    variant="danger"
                                    size="sm"
                                    @click="form.expiry = null"
                                    class="ml-2 btn btn-clean btn-md svg-icon svg-icon-light"
                                >
                                    <inline-svg src="/media/svg/icons/General/Trash.svg" />
                                </b-button>
                            </div>
                        </b-form-group>
                        <!-- Limit use -->
                        <b-form-group
                            description="Si vous ne remplissez pas le champ ci-dessus, l'utilisation du lien sera illimitée, dans la mesure où il est actif."
                            label="Nombre d'utilisation maximum :"
                            label-for="limite-use"
                        >
                            <b-form-input type="number" id="limite-use" v-model="form.limit" />
                        </b-form-group>
                        <!-- Double check -->
                        <b-form-group>
                            <b-form-checkbox v-model="form.valid">
                                Demander une validation avant de finaliser l'inscription des stagiaires
                            </b-form-checkbox>
                        </b-form-group>
                        <!-- Online status -->
                        <b-form-group label="Condition d'inscription" label-for="select-customer">
                            <b-form-select name="select-customer" v-model="form.mail_condition">
                                <b-form-select-option :value="null">Libre</b-form-select-option>
                                <b-form-select-option value="forceMail">Avec adresse email</b-form-select-option>
                                <b-form-select-option value="forceNoMail">Sans adresse email</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </b-overlay>
                </b-form>
                <p class="text-muted">* : Champs obligatoire</p>
            </section>
        </template>
        <template #modal-footer>
            <b-button variant="danger" size="sm" @click="$bvModal.hide('modal-update-link')">Annuler</b-button>
            <b-button variant="primary" size="sm" @click="updateLink()">Enregistrer</b-button>
        </template>
    </b-modal>
</template>

<script>
import { LIST_ALL_CLIENT } from '@/core/services/store/api/client.service';
import ApiService from '../../core/services/api.service';

export default {
    name: 'ModalUpdateLink',
    data() {
        return {
            form: {
                classes_customers_id: null,
                customer_id: null,
                trainee_choice: false,
                expiry: null,
                limit: null,
                valid: false,
                mail_condition: null
            },
            customersOptions: [],
            classesOptions: [],
            customerSelected: 0,
            loading: false
        };
    },
    props: {
        customerId: {
            type: Number,
            required: true
        },
        classeId: {
            type: Number,
            required: true
        },
        dataLink: {
            type: Object,
            required: false
        },
        baseCustomerId: {
            type: Number,
            required: false
        }
    },
    methods: {
        getCustomers() {
            const data = {
                params: {
                    customer_id: this.baseCustomerId,
                    force: true
                }
            };
            this.$store.dispatch(LIST_ALL_CLIENT, data).then((result) => {
                this.customersOptions = [
                    {
                        value: 0,
                        text: 'Aucun établissement sélectionné'
                    }
                ];
                result &&
                    result.data &&
                    result.data.data.map((customer) => {
                        this.customersOptions.push({
                            value: customer.id,
                            text: `${customer.name} (${customer.code})`
                        });
                    });
            });
        },
        checkCustomerSelected() {
            this.customerSelected !== 0 ? (this.form.trainee_choice = false) : (this.form.trainee_choice = true);
            this.form.customer_id = this.customerSelected;
        },
        updateLink() {
            this.loading = true;
            this.form.trainee_choice === true ? (this.form.customer_id = this.baseCustomerId) : null;
            ApiService.put(`academy/entryLink/${this.dataLink.id}`, this.form)
                .then(() => {
                    this.loading = false;
                    this.$bvModal.hide('modal-update-link');
                    this.$bvToast.toast('Le lien a bien été mis à jour.', {
                        title: 'Mise à jour effectuée',
                        variant: 'success',
                        solid: true
                    });
                    this.$emit('finish-update');
                })
                .catch(() => {
                    this.loading = false;
                    this.$bvToast.toast("Une erreur est survenue lors de la mise à jour du lien. Aucune modification n'a été apportée.", {
                        title: "Une erreur s'est produite",
                        variant: 'danger',
                        solid: true
                    });
                });
        }
    },
    watch: {
        customerId: function () {
            this.getCustomers();
        },
        'form.trainee_choice': function () {
            this.form.trainee_choice === true ? (this.customerSelected = 0) : null;
        },
        dataLink: function () {
            this.getCustomers();
            if (this.dataLink.forceMail && !this.dataLink.forceNoMail) {
                this.form.mail_condition = 'forceMail';
            } else if (!this.dataLink.forceMail && this.dataLink.forceNoMail) {
                this.form.mail_condition = 'forceNoMail';
            } else {
                this.form.mail_condition = null;
            }

            this.dataLink.select_customer = parseInt(this.dataLink.select_customer);

            this.dataLink.select_customer ? (this.customerSelected = 0) : (this.customerSelected = this.dataLink.customers_id);

            this.form = {
                ...this.form,
                ...this.dataLink,
                classes_customers_id: this.classeId,
                customer_id: this.dataLink.select_customer ? 0 : this.dataLink.customers_id,
                trainee_choice: this.dataLink.select_customer == 1 ? true : false,
                expiry: this.dataLink.expiry ? new Date(this.dataLink.expiry) : null,
                limit: this.dataLink.limit,
                valid: this.dataLink.valid == 1
            };
        }
    }
};
</script>
